<template>
  <div style="position: relative">
    <div style="position: absolute; top: 6vh; left: 15vw; width: 50%">
      <el-form ref="form" label-width="120px">
        <h3 style="text-align: left;margin: 20px 0;">网址下载APP：<a style="color:#f98611;"
                                                                href="http://public.half-half.cn/apk/common.apk">点此下载APP安装包</a>
        </h3>
        <h3 style="text-align: left;margin: 20px 0;">浏览器扫码下载APP</h3>
        <img src="@/assets/app.png" width="200" alt="">
        <div style="height: 30px"></div>
        <h3 style="text-align: left;margin: 20px 0;">微信小程序</h3>

        <img src="@/assets/mini.jpg" width="200" alt="">

      </el-form>

    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      form: {},

    };
  },
  mounted() {
  },
  methods: {},
};
</script>
